<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <div
            class="d-flex flex-column justify-content-center align-items-center"
          >
            <b-img
              :src="appLogoImage"
              alt="logo"
              style="width: 50px"
              class="img-fluid"
            />
            <h3 class="text-center">{{ appName }}</h3>
          </div>
        </b-link>

        <b-card-title class="mb-1"> Forgot Password? 🔒 </b-card-title>
        <b-card-text class="mb-2">
          Enter your email and we'll send you instructions to reset your
          password
        </b-card-text>
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="emailVerify"
          >
            <b-form-group label="Email" label-for="forgot-password-email">
              <validation-provider name="Email">
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                  required
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              </validation-provider>
            </b-form-group>
            <b-button variant="primary" block type="submit">
              <span v-if="!loading">Send reset link</span>
              <b-spinner label="Spinning" v-else></b-spinner>
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{ name: 'auth-login' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>
  
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BSpinner,
  BImg,
} from "bootstrap-vue";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
import router from "@/router";

import { getAccessTokenFromCookie } from "@/auth/utils";

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BImg,
  },
  data() {
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      userEmail: "",
      loading: false,
      appLogoImage,
      appName,
    };
  },
  methods: {
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    emailVerify() {
      this.loading = true;
      axios
        .post("/verify-email-for-reset", {
          email: this.userEmail,
        })
        .then(({ data }) => {
          if (data.success) {
            this.showToast(
              "success",
              "SaveIcon",
              "Success",
              "Please Check your Email"
            );
            router.push({ name: "auth-login" });
          } else {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              data.message
            );
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Something went wrong!"
          );
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    const data = getAccessTokenFromCookie();
    if (data) {
      const role = data[2].role;
      if (role === "admin") {
        next("/admin-dash");
      } else {
        next("dashboard");
      }
    } else {
      next(true);
    }
  },
};
</script>
  
<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
  